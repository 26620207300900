@import 'variables';
@import 'buttons';
@import 'texts';
@import 'background';
@import 'overwrites';
@import 'inputs';

body {
  margin: 0;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $grey-light; }

#root {
  min-width: 695px; }

nav {
  height: 70px; }

.container-auth {
  height: 100vh; }

.nav-link {
  display: flex;
  align-items: center; }

.nav-link.active {
  background-color: white !important;
  color: white !important; }

.spinner-border {
  vertical-align: baseline !important; }

.custom-card {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;

  & .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem; }


  & .card-body {
    padding: 2rem; }

  & .form {
    width: 100%; }

  & .form .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s; }

  & .form-label-group {
    position: relative;
    margin-bottom: 1rem; }

  & .form-label-group input {
    height: auto;
    border-radius: 2rem; }

  & .form-label-group>input,
  & .form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x); }

  & .form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: $grey-dark;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out; }

  & .form-label-group input::-webkit-input-placeholder {
    color: transparent; }

  & .form-label-group input:-ms-input-placeholder {
    color: transparent; }

  & .form-label-group input::-ms-input-placeholder {
    color: transparent; }

  & .form-label-group input::-moz-placeholder {
    color: transparent; }

  & .form-label-group input::placeholder {
    color: transparent; }

  & .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3); }

  & .form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777; } }
li {
  list-style: none; }

tbody tr:hover:not(.non-hover) {
  cursor: pointer;
  background-color: rgba(0,0,0,.05); }

.fa-chevron-left {
  cursor: pointer; }

.user-select-none {
  user-select: none; }

.pointer-events-none {
  pointer-events: none; }

.svg-primary {
  path {
    fill: $primary-color; } }

.svg-white {
  path {
    fill: white; } }

.svg-dark {
  path {
    fill: $grey-dark; } }
#printable {
  display: none !important; }

@media print {
  .no-print {
      display: none !important; }
  #printable {
      display: block !important; } }
/* Cursor */

.grab {
  cursor: grab !important; }

.grabbing {
  cursor: grabbing !important; }

.pointer {
  cursor: pointer !important; }

.crosshair {
  cursor: crosshair !important; }

.not-allowed {
  cursor: not-allowed !important; }

// Google Map search list
.pac-container {
  z-index: 10000; }
