@import 'src/styles/variables';

.defaultParams {
  margin-top: 1rem; }

.buttons {
  margin: 3px; }

.textArea {
  font-size: 13px !important; }
