@import 'src/styles/variables';

.buttonWrapper {
  display: flex;
  align-items: center;
  height: 45px;
  & > button {
    margin-left: 5px;
    margin-right: 5px; } }

.tableRow {
  cursor: default !important; }

.icon {
  margin: 0 12px;
  cursor: pointer; }
