$primary-color: #3A9CED;
$danger-color: #FB745B;
$grey-light: #fafbfd;
$grey-med-dark: #eff0f5;
$grey-dark: #495057;
$green: #27CEBB;
$snuff: #D8DBEA;
$azure: #3662A2;
$loblolly: #B7BDC8;
$athens-grey: #EEF2F4;
$steel-blue: #528EBA;
$aqua-haze: #F8FAFB;
$whisper: #ECEEF5;
$mystic: #E4E6F0;
$side-navigation-hover-color: #F8F9FA;
$red: #EA3223;
$orange: #F0874D;
$orange-light: #FEB95A;

$general-text-color: #242E42;
$general-text-font-size: 12px;

$primary-bg-color: $primary-color;
$primary-text-color: #ffffff;

$disabled-bg-color: #C8C8C8;
$disabled-text-color: #ffffff;

$btn-font-size-sm: 12px;
$btn-font-size-md: 13px;
$btn-font-size-lg: 15px;
