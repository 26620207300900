@import 'src/styles/variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 130px;
  font-weight: 300;
  color: white;
  border-radius: 4px;
  border: none;
  outline: none !important;
  & > span:not(:first-child) {
    margin-left: 6px; }
  &-primary {
    background-color: $primary-color; }
  &-primary-outline {
    background-color: white;
    color: $general-text-color;
    border: 1px solid $primary-color; }
  &-secondary {
    background-color: $grey-dark; }
  &-success {
    background-color: $green; }
  &-danger {
    background-color: $danger-color; }
  &-danger-outline {
    background-color: white;
    color: $general-text-color;
    border: 1px solid $danger-color; }
  &-sm {
    height: 35px;
    font-size: 12px; }
  &-md {
    height: 40px;
    font-size: 13px; }
  &-disabled {
    background-color: $disabled-bg-color; } }

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }
