@import 'src/styles/variables';

.toolbar {
  height: 75px;
  padding: 0 20px; }
.title {
  font-size: 16px !important; }
.btnFilter {
  min-width: 43px; }
.btnCreate {
  margin-left: 10px;
  font-weight: 500;
  opacity: 1;
  font-size: 13px;
  padding-left: 2rem;
  padding-right: 2rem; }
.btnRefresh {
  margin-left: 20px;
  margin-right: 10px;
  min-width: 115px;
  height: 40px; }
