@import 'src/styles/variables';

.validated {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: tableRow;
  justify-content: right;

  & > p {
    margin: 1rem; } }

.card_label {
  margin: 1rem;
  margin-right: 4rem;
  padding-left: 0.5rem;
  color: #242E42;
  font-size: 13px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center; }
