@import 'src/styles/variables';

.individualRule {
  margin: 30px 0;
  border-top: 1px solid rgba($snuff, 0.95); }
.individualTitle {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
  text-transform: uppercase; }
.individualChartWrapper {
  margin-bottom: 30px; }
.individualNoDataTable {
  text-align: center;
  padding: 25px 100px;
  background-color: rgba($athens-grey, 0.33);
  & > span {
    font-weight: 300; } }

@media (min-width: 992px) {
  .individualChartWrapper {
    margin-bottom: 0; } }
