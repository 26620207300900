
// Text colors
.text-primary {
  color: $primary-color !important; }
.text-grey-light {
  color: $grey-light !important; }
.text-green {
  color: $green !important; }

// Text

.h1-title-primary {
  color: #3A9CED;
  font-size: 30px; }

.h1-title-black {
  color: #242E42;
  font-size: 30px; }

.h2-title {
  color: #242E42;
  opacity: .9;
  font-size: 18px; }

.menu-points {
  cursor: pointer;
  color: #242E42;
  opacity: .9;
  font-size: 14px; }

.selected-point, .selected-point:hover {
  color: #60B7FF;
  opacity: .9;
  font-size: 14px;
  svg path {
    fill: #60B7FF;
    stroke: #60B7FF; } }

.selected-nav-point {
  color: #242E42;
  opacity: .9;
  font-size: 14px;
  border-left: 5px solid $primary-color;
  background-color: $side-navigation-hover-color;
  border-top-left-radius: unset!important;
  border-bottom-left-radius: unset!important; }

.highlight-text-1 {
  color: $general-text-color;
  opacity: .75;
  font-size: 16px; }

.general-text-1 {
  color: $general-text-color;
  opacity: .9;
  font-size: $general-text-font-size; }

.general-text-2 {
  color: $general-text-color;
  opacity: .6;
  font-size: $general-text-font-size; }

.general-text-3 {
  color: $general-text-color;
  opacity: .4;
  font-size: $general-text-font-size; }

.general-text-4 {
  color: #DBDEEC;
  font-size: 14px; }

.general-text-5 {
  color: $green;
  font-size: 14px; }

::placeholder {
  color: $general-text-color;
  font-size: 13px; }

.general-error {
  color: $danger-color; }
