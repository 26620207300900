@import 'src/styles/variables';

.card_label {
  margin: 1rem;
  margin-right: 4rem;
  padding-left: 0.5rem;
  color: #242E42;
  font-size: 13px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center; }
