@import 'src/styles/variables';

.hint {
  padding-left: 20px;
  padding-right: 20px; }

.btnSave {
  margin: 10px 20px 30px 0;
  position: unset!important; }

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05); }

.agencyDropdown {
  display: flex;
  font-size: 12px;
  padding: 8px 0px;
  margin-bottom: 16px;
  align-items: center;
  & > span {
    margin-right: 8px; } }
