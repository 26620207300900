.logoLink {
  text-decoration: none !important; }
.title {
  color: white;
  font-size: 14px; }
.whiteText {
  color: white !important; }
.dFlex {
  display: flex; }
.dFlexColumn {
  flex-direction: column; }
.username {
  font-size: 13px;
  color: white; }
.currentTime {
  font-size: 12px;
  margin-top: 6px;
  color: white;
  opacity: 0.6;
  min-width: 175px;
  font-weight: 300;
  text-transform: uppercase; }
.arrowDownIcon path {
  fill: white;
  stroke: white; }
.logOutBtnYes {
  background-color: #3A9CED !important; }
.logOutBtnNo {
  background-color: #495057 !important; }
