@import 'src/styles/variables';

.header {
  padding: 20px; }

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px !important;
  & > span {
    font-size: 16px;
    font-weight: 300; }
  & a {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    & > span {
      font-size: 16px;
      margin-left: 4px; } } }

.linkWrapper {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 30px;
  & > a {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 35px;
    margin-right: 1px;
    font-size: 14px;
    font-weight: 300;
    background-color: $snuff;
    text-decoration: none !important;
    &.active {
      color: white;
      opacity: 1;
      background-color: $primary-color;
      box-shadow: 0 2px 6px rgba($steel-blue, 0.45); } } }

@media (min-width: 992px) {
  .linkWrapper {
    margin-top: 0px; } }
