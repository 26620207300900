@import 'src/styles/variables';

.contentContainer {
  top: 15px;
  position: relative;
  margin-left: 85px;
  flex: 1;
  height: 100%;
  overflow: auto;
  background-color: white; }

@media (min-width: 1199px) {
  .contentContainer {
    margin: 0 2.5%;
    margin-left: 270px; } }
